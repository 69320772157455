import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Button Navigation`}</inlineCode>{` merupakan bilah navigasi bawah yang menampilkan tiga hingga lima tujuan di bagian bawah layar. Setiap tujuan diwakili oleh ikon dan label teks yang opsional. Ketika ikon navigasi bawah ditap, pengguna akan dibawa ke tujuan navigasi tingkat atas yang terkait dengan ikon itu. Komponen ini hanya digunakan pada mobile aplikasi.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard subTitle="Three navigations" subTitleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAxUlEQVQ4y+2Q3QqDMAyFff+X2yvsajqUwWza9D9rajsq6BjuYjcGQk5P6teDnURLoAwpbYliJK5Y5qeKzV3+lhnM6k7gCTyB/wXiAowHej/hkUpA3ALyDCGQ84HQ+KxrO+/J+9aLZK19e2oPyPUAR5crrP7TOE7UD/eVd+sHEkJkvQsM6UVAR9PT5NdzspQAQNIsYEnrOG3IZ4WY96phdFXwnEGTkJqk0lnPkicmz5Q9Fk/nsyj7lrFKWBsa/W1vJvylK+MFck8nMKF0kngAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/deb8b8c4865b2db78a925766e92ede28/36a81/3.png",
              "srcSet": ["/static/deb8b8c4865b2db78a925766e92ede28/7fc1e/3.png 288w", "/static/deb8b8c4865b2db78a925766e92ede28/36a81/3.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard subTitle="Four navigations" subTitleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA1UlEQVQ4y+2Puw7CMAxF+/8/xwZiQ8BSutA0DzdJY2y3qVr1ISQGlkay7k3snNwUxnnUtkULHjEl5JUG3VtpMst3mcGs4gAewAP4dyA3twtHn0G5thN+kWjxA/JuFUjadR223mOMUXyIfbFniLEWAUB8jP0s93YSJrw/nnTRyeOV8ni66THM+XLF8lWJ9wTj2SQJwxLIGihZrRpK0crL2gUs3yCJOVGjDToH40/qWhE4zBNmw6o0iDYGyDtRY8nLHmQ/7eXZKWOWcK30xvlibi3hL5UZHz9xJlgVl8AyAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/09c7b4d91b8575411679832bd36911cf/36a81/4.png",
              "srcSet": ["/static/09c7b4d91b8575411679832bd36911cf/7fc1e/4.png 288w", "/static/09c7b4d91b8575411679832bd36911cf/36a81/4.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard subTitle="Five navigations" subTitleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA3klEQVQ4y+2PSQ7CMAxFuf/duAEzhQ0COmRqpk9sCK1KQUIs2LSS5afEfvmdSW0hVAtlLBAj6IuP/umLvVnaJQe5ZpNwEk7Cvwvpclh5acj9Ghem3r3+muQTv03ofIBufRoO8N5z0aIxBlIqkKK1ac7dz21imhkVttahlA7zdcOvXssK233BvN7ssFiumPfFAefzhbk4HCGESLt+PKHUDqfKIIQAqTSqukkcIVI6qRRz3QhOS0z3Shto4zphBuqNTL+mDGqhmeksMxVxnutz3/FMSJ350b+t0YS/VHbcAB1yJYl+HZiGAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/ef51027b424701605c76fd5edd3b3799/36a81/5.png",
              "srcSet": ["/static/ef51027b424701605c76fd5edd3b3799/7fc1e/5.png 288w", "/static/ef51027b424701605c76fd5edd3b3799/36a81/5.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      